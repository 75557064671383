import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
/*import pic03 from '../images/pic03.jpg'*/
import pic03 from '../images/logo_white.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        role={"button"}
        onKeyDown={this.handleClick}
        tabIndex={0}
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            The world has already changed. The value of quarterly earning calls, annual leadership retreats, and 5 year strategic plans is gone.  More importantly, the rate at which change occurs is accelerating.  Those who talk about making plans to cope with this reality are already too late.
          </p>
          <p>
            <strong>HD DataWorks</strong> provides advice and solutions for managing tomorrow.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            HD DataWorks starts with a simple premise: <strong>there are patterns everywhere in nature</strong>. These patterns extend to humanity; to what we do and what we build.
          </p>
          <p>
            Using broad expertise and experience in data science, software engineering, and complex systems architecture, HD DataWorks helps clients identity the patterns most relevant to them, infer how those patterns will play out, and implement solutions to capitalize on that foresight.
          </p>
          {close}
        </article>

        <article
          id="payment"
          className={`${this.props.article === 'payment' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Payment</h2>
          <span className="image-no-overlay main">
            <img src={pic03} alt="" style={{content: 'none', position: 'relative', maxWidth: '75%', height: 'auto', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}} />
          </span>
          <p align={'center'} >
            Clients can pay for services <a href='https://link.waveapps.com/u8ghg8-9cqtgd' target='_blank' rel='noopener noreferrer'>here</a>.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
                <input type="hidden" name="form-name" value="contact" />
              </li>
            </ul>
          </form>
          {/*<ul className="icons">*/}
          {/*  <li>*/}
          {/*    <a*/}
          {/*      href="https://twitter.com/HuntaroSan"*/}
          {/*      className="icon fa-twitter"*/}
          {/*    >*/}
          {/*      <span className="label">Twitter</span>*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="https://codebushi.com" className="icon fa-facebook">*/}
          {/*      <span className="label">Facebook</span>*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="https://codebushi.com" className="icon fa-instagram">*/}
          {/*      <span className="label">Instagram</span>*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a*/}
          {/*      href="https://github.com/codebushi/gatsby-starter-dimension"*/}
          {/*      className="icon fa-github"*/}
          {/*    >*/}
          {/*      <span className="label">GitHub</span>*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
