import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.png'


const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/*<span className="icon fa-diamond"></span> display: 'block', margin: 'auto', position: 'relative', maxWidth: '75%', maxHeight: 'auto', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' */}
      <img src={logo} alt="" style={{position: 'relative', maxWidth: '75%', height: 'auto', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', top: '12%'}} />
    </div>
    <div className="content">
      <div className="inner">
        <h1>All this has happened before<br />and all this will happen again.</h1>
        <p>

          <br />
          <strong>HD DataWorks LLC</strong> helps set your bearings for the future with insights from the past
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('payment')
            }}
          >
            Payment
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
